<template>
    <div class="container-fluid">
        <Quote title="Bu yerda izohlaringizni o'zgartirishingiz yoki o'chirishingiz mumkin"/>
        <GoBack next="Izohlarim"/>
        <div class="row justify-content-center">
            <div class="col-11 col-md-11 col-lg-11 col-xl-11 col-xxl-11 overflow-auto">
                <h3
                    v-if="getAllComments.length > 0"
                    class="text-center mt-4 mb-4"
                >
                    {{ this.getUserInfo.givenName }} {{ this.getUserInfo.familyName }} izohlari:
                </h3>
                <h3
                    v-else
                    class="text-center mt-4 mb-4"
                >
                    Hozircha sizning izohlaringiz mavjud emas:
                </h3>
                <table v-if="getAllComments.length > 0" class="table table-hover border">
                    <thead>
                    <tr>
                        <th class="col-1 id">Id</th>
                        <th class="col-9 name">Izohlar</th>
                        <th v-if="getAllComments.some(el => el.isApprove === false)" class="col-auto surname d-none d-sm-table-cell">O'zgartirish</th>
                        <th v-if="getAllComments.some(el => el.isApprove === false)" class="col-auto status d-none d-sm-table-cell">O'chirish</th>
                        <th class="d-sm-none"></th>
                        <th class="d-sm-none"></th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="comment in getAllComments" :key="comment.id">
                        <td
                            class="col-1"
                            v-if="!comment.isDeleted"
                        >
                            {{ comment.id }}
                        </td>
                        <td
                            class="col-9"
                            v-if="!comment.isDeleted"
                        >
                            {{ comment.text }}
                        </td>
                        <td class="col-auto d-none d-sm-table-cell" v-if="!comment.isApprove && !comment.isDeleted">
                            <router-link
                                :to="'/edit-comment/' + comment.id"
                                class="hover-decoration"
                            >
                                O'zgartirish
                            </router-link>
                        </td>
                        <td class="col-auto d-none d-sm-table-cell" v-if="!comment.isApprove && !comment.isDeleted">
                            <button
                                type="button"
                                class="btn btn-danger agree-btn"
                                @click="showModal(comment.id)"
                            >
                                O'chirish
                            </button>
                        </td>
                        <td class="col-auto d-sm-none" v-if="!comment.isApprove && !comment.isDeleted">
                            <router-link
                                :to="'/edit-comment/' + comment.id"
                            >
                                <img class="pen mr-2" src="../assets/pen.png" alt="">
                            </router-link>
                        </td>
                        <td class="col-auto d-sm-none" v-if="!comment.isApprove && !comment.isDeleted">
                            <button
                                type="button"
                                @click="showModal(comment.id)"
                                style="border: none; background-color: white; font-size:1.7vw;"
                            >
                                <img class="p-0 ms-sm-3" src="../../src/assets/trash.png">
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mt-3">
            <b-pagination
                v-if="getAllCommentsTotal >= 20"
                v-model="currentPage"
                pills
                align="center"
                :per-page="20"
                :total-rows="getAllCommentsTotal"
                @input="fetchUserCommentsByPage"
                :click-handler="fetchUserCommentsByPage"
            >
            </b-pagination>
        </div>

        <div>
            <b-modal ref="modal-delete" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Ushbu izohingizni rostdan ham o'chirmoqchimisiz?</h3>
                </div>
                <b-button
                    class="mt-3"
                    style="width: 100px"
                    variant="btn btn-primary"
                    size="sm"
                    @click="hideErrorModal"
                >
                    Yo'q
                </b-button>
                <b-button
                    class="mt-3 float-end"
                    style="width: 100px"
                    variant="btn btn-danger"
                    size="md"
                    @click="deleteMyComment"
                >
                    Ha
                </b-button>
            </b-modal>
        </div>

        <div>
            <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Izohingiz o'chirildi</h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    style="width: 100px"
                    variant="btn btn-primary"
                    size="md"
                    @click="hideErrorModal"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import Quote from "../components/Quote";
import GoBack from "../components/GoBack";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "MyComments",
    components: {
        Quote,
        GoBack,
    },
    data() {
        return {
            show: true,
            commentId: null,
            currentPage: 1,
            page: 1,
        }
    },
    computed: {
        ...mapGetters(['getUserInfo', 'getAllCommentsTotal', 'getAllComments'])
    },
    watch: {
        '$route.params.page': {
            handler: function (val) {
                this.currentPage = val ? val : 1
                this.fetchUserComments({page: this.currentPage, userId: this.getUserInfo.id})
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions(['fetchUser', 'deleteComment', 'fetchUserComments']),
        deleteMyComment() {
            this.deleteComment(this.commentId)
                .then(() => {
                    this.$refs['modal-delete'].hide()
                    setTimeout(this.hideErrorModal, 3000)
                    this.$refs['modal-success'].show()
                    this.fetchUserComments()
                })
        },
        fetchUserCommentsByPage() {
            this.$router.push('/my-comments/page/' + this.currentPage)
        },
        showModal(id) {
            this.commentId = id
            this.$refs['modal-delete'].show()
        },
        hideErrorModal() {
            this.$refs['modal-delete'].hide()
            this.$refs['modal-success'].hide()
        },

    },
    mounted() {
        this.show = true
        this.fetchUser()
            .then(() => {
                this.fetchUserComments({page: this.currentPage, userId: this.getUserInfo.id})
                    .then(() => {
                        this.show = false
                    })
            })
    }
}
</script>

<style scoped>

img {
    width: 20px;
    height: 20px;
    margin-bottom: 1rem;
}

</style>
